<template>
  <a-modal
    :title="model?'编辑':'新建'"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="耗材" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            showSearch
            allow-clear
            v-decorator="['consumables_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            placeholder="请输入名称"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="consumables_fetching ? undefined : null"
            @search="consumablesSearch"
            @change="handleConsumablesDataChange"
          >
            <a-spin v-if="consumables_fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in consumablesList" :key="d.id" :value="d.id">
              {{ d.consumables }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['count', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { listMallConsumbales } from '@/api/applet_mall_consumbales'
export default {
  name: 'GoodsGroupCreate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      fetching: false,
      consumables_fetching: false,
      wmsGoodsList: [],
      consumablesList: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.model)
    })
    this.fetchConsumablesData(undefined)
  },
  methods: {
    fetchConsumablesData (value) {
      this.consumables_fetching = true
      listMallConsumbales({ consumables: value }).then(({ data }) => {
        const result = data.entries || []
        this.consumablesList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
        this.consumables_fetching = false
      })
    },
    handleConsumablesDataChange (value) {
      console.log(value)
    },
    consumablesSearch (value) {
      if (value !== undefined) {
        this.fetchConsumablesData(value)
      }
    },
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, ['consumables_id', 'count'])
      console.log('formData', formData)
      this.id = formData.id
      form.setFieldsValue(formData)
      this.loading = false
    }
  }
}
</script>
